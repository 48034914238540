import React from "react"

const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600" {...props}>
    <path
      d="M711.94 187.75c-.48-25.61-14.74-50.48-37.2-64.92-60.62-38.95-152-89.83-195.32-112.77a75.79 75.79 0 00-74.72 0C361.34 33 270 83.87 209.34 122.83c-9.51 6.12-24.62 16.87-34 27.26a2.71 2.71 0 002.21 4.52q6.06-.47 12.35-.46h5.18l3.21.1h.92q1.65.08 3.27.18h.74c1.15.07 2.3.16 3.44.25h.52c1.22.1 2.44.22 3.65.35h.3c1.28.14 2.56.29 3.82.45h.14c1.33.17 2.64.35 3.95.55l1.29.2a6.06 6.06 0 004.72-1.25q1.68-1.34 3.49-2.51c59.8-38.14 150-88.38 192.8-111a40.43 40.43 0 0141.47 0c42.83 22.65 133 72.89 192.71 111.25 12.43 8 20.62 22 20.88 35.68.72 37.23 1.1 74.77 1.13 111.58s-.41 74.35-1.13 111.59c-.26 13.68-8.45 27.69-20.87 35.67-59.72 38.38-149.9 88.61-192.72 111.26a40.43 40.43 0 01-41.47 0c-42.53-22.5-131.85-72.05-191.51-110.45a4.59 4.59 0 00-3.32-.61c-3.33.62-6.74 1.17-10.22 1.61l-1.48.18a179.008 179.008 0 01-8.13.77l-2.1.13c-1.32.08-2.64.14-4 .2l-1.91.06c-2 .06-3.91.1-5.9.1-3.4 0-6.76-.08-10.1-.23a2.07 2.07 0 00-1.75 3.28c7.42 9.72 20.42 18.46 28.42 23.64 60.67 39 152 89.85 195.33 112.76a75.77 75.77 0 0074.71 0c43.37-22.94 134.7-73.8 195.36-112.77 22.46-14.44 36.71-39.32 37.2-64.91.71-37.44 1.09-75.2 1.14-112.24-.08-37.02-.44-74.83-1.14-112.27z"
      fill="#50e3c2"
    />
    <path
      d="M0 183.69h50.34v236.54H0zm321.74 118.45c0-72.7 55-122.35 124.13-122.35 53.9 0 84 28.37 100.71 57.81l-43.24 21.28a64.79 64.79 0 00-57.46-34.4c-41.13 0-72.34 33-72.34 77.66s31.21 77.67 72.34 77.67c26.25 0 47.53-14.9 57.46-34.4l43.26 20.92c-17 29.43-46.81 58.16-100.72 58.16-69.17 0-124.14-49.65-124.14-122.35zM120.09 348.6a103.76 103.76 0 0075.18 31.91c27.67 0 41.15-13.12 41.15-26.59 0-17.73-20.58-23.76-47.87-30.15-38.66-8.86-88.31-19.5-88.31-72.34 0-39.37 34-71.28 89.73-71.28 37.59 0 68.79 11.35 92.2 33l-28 36.85c-19.15-17.73-44.68-25.89-67.73-25.89-22.7 0-34.75 9.93-34.75 24.12 0 16 19.85 20.92 47.16 27.3 39 8.87 88.66 20.57 88.66 73.06 0 43.26-30.85 75.89-94.69 75.89-45.39 0-78-15.25-100.36-37.59zm520-164.91h-51.75v236.54h50.36V261.36l24.85 35c0-26.82-.27-53.9-.67-80.84zm109.58 0v153.2l-22.59-31.58c-.06 26.37-.28 53-.67 79.65l25 35.27H800V183.69z"
      fill="#fff"
    />
  </svg>
)

export default Logo
